import React, { useState, useRef, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import useAuth from '../../hooks/useAuth'

import { CartContext } from '../../context/CartContext';

import PageHeader from '../Header/PageHeader'
import { RiEdit2Line, RiSave3Line } from 'react-icons/ri'

export default function Dashboard() {

	const { state, updateProfile } = useAuth()

	const form = useRef();
	const { setPostage } = useContext( CartContext );

	const [ editMode, setEdit ] = useState( false );
	const [ firstName, setFirstName ] = useState( state.user.firstName );
	const [ lastName, setLastName ] = useState( state.user.lastName );
	const [ addressLine1, setAddressLine1 ] = useState( state.user.addressLine1 );
	const [ addressLine2, setAddressLine2 ] = useState( state.user.addressLine2 );
	const [ city, setCity ] = useState( state.user.city );
	const [ postcode, setPostcode ] = useState( state.user.postcode );
	const [ country, setCountry ] = useState( state.user.country );
	const [ region, setRegion ] = useState( state.user.region );
	const [ phoneNumber, setPhoneNumber ] = useState( state.user.phoneNumber );
	
	const data = useStaticQuery(graphql`
		query accountDetails {
			strapi {
				countries {
					name
					id
					shipping_zone {
						id
					}
				}
			}
		}
		`
	)

	const countries = data.strapi.countries

	const handleCountryChange = ( event ) => {
		setCountry( event.target.value )
		// update postage and shipping state so checkout postage options refreshed
		setPostage({ price: 0 })
	}

	const handleFormSubmit = async ( event ) => {
		event.preventDefault();
		form.current.reportValidity();

		const profileData = {
			firstName,
			lastName,
			addressLine1,
			addressLine2,
			city,
			postcode,
			country,
			region,
			phoneNumber
		}
		
		try {
			await updateProfile( profileData );
		} catch ( error ) {
			console.log( error )
		}

		setEdit(!editMode)
	}

	return (
		<>
			<PageHeader title="Account Overview"/>

			<div className="account-details">
				<div className="account-details__heading">
					Name &amp; Address
					{ !editMode &&
						<button className="account-details__edit" onClick={() => setEdit(!editMode)}>
							<RiEdit2Line className="account-details__edit-icon" />
							Edit details
						</button>
					}
				</div>
				{ !editMode ? (
					<>
						<div className="account-details__text">{ state.user.firstName } { state.user.lastName }</div>
						<div className="account-details__text">{ state.user.addressLine1 }</div>
						<div className="account-details__text">{ state.user.addressLine2 }</div>
						<div className="account-details__text">{ state.user.city }</div>
						<div className="account-details__text">{ state.user.region }</div>
						<div className="account-details__text">{ state.user.postcode }</div>
						<div className="account-details__text">{ state.user.country }</div>
						<div className="account-details__text">{ state.user.phoneNumber }</div>
					</>
				) : (
					<form className="form" onSubmit={ handleFormSubmit } ref={ form }>
						<div className="form__input-container">
							<label className="form__label"  htmlFor="firstName">First Name</label>
							<input className="form__input" type="text" name="firstName" id="firstName" value={ firstName } onChange={ e => { setFirstName( e.target.value ) } } required />
						</div>
						<div className="form__input-container">
							<label className="form__label"  htmlFor="lastName">Last Name</label>
							<input className="form__input" type="text" name="lastName" id="lastName" value={ lastName } onChange={ e => { setLastName( e.target.value ) } } required/>
						</div>

						<div className="form__input-container">
							<label className="form__label"  htmlFor="addressLine1">House No/Name</label>
							<input className="form__input" type="text" name="addressLine1" id="addressLine1" value={ addressLine1 } onChange={ e => { setAddressLine1( e.target.value ) } } required/>
						</div>
						<div className="form__input-container">
							<label className="form__label"  htmlFor="addressLine2">Address 2 <span className="form__label-optional">(optional)</span></label>
							<input className="form__input" type="text" name="addressLine2" id="addressLine2" value={ addressLine2 } onChange={ e => { setAddressLine2( e.target.value ) } }/>
						</div>
						<div className="form__input-container">
							<label className="form__label"  htmlFor="city">City</label>
							<input className="form__input" type="text" name="city" id="city" value={ city } onChange={ e => { setCity( e.target.value ) } } required/>
						</div>
						<div className="form__input-container">
							<label className="form__label"  htmlFor="postcode">Postcode</label>
							<input className="form__input" type="text" name="postcode" id="postcode" value={ postcode } onChange={ e => { setPostcode( e.target.value ) } } required/>
						</div>

						<div className="form__input-container">
							<label className="form__label"  htmlFor="country">Country</label>
							<select
								className="form__input"
								type="text"
								name="country"
								id="country"
								required
								value= { country }
								onChange={ e => handleCountryChange( e ) }
								onBlur={ e => handleCountryChange( e ) }>
									{ countries.map( node => {
										return (
											<option
												value={ node.name }
												key={ node.id }>
												{ node.name }</option>
										)
									} ) }
							</select>
						</div>

						<div className="form__input-container">
							<label className="form__label"  htmlFor="region">Region/State</label>
							<input className="form__input" type="text" name="region" id="region" value={ region } onChange={ e => { setRegion( e.target.value ) } } required/>
						</div>

						{ country === "United Kingdom" ? (
							<div className="form__input-container">
								<label className="form__label"  htmlFor="phoneNumber">Phone Number <span className="form__label-optional">(optional)</span></label>
								<input className="form__input" type="text" name="phoneNumber" id="phoneNumber" placeholder="Please include area code, eg +44" value={ phoneNumber } onChange={ e => { setPhoneNumber( e.target.value ) } }/>
							</div>
						) : (
							<div className="form__input-container">
								<label className="form__label"  htmlFor="phoneNumber">Phone Number <span className="form__label-optional">- required for international shipping</span></label>
								<input className="form__input" type="text" name="phoneNumber" id="phoneNumber" placeholder="Please include area code, eg +44" value={ phoneNumber } onChange={ e => { setPhoneNumber( e.target.value ) } } required/>
							</div>
						) }

						<button className="account-details__save" type="submit">
							<RiSave3Line className="account-details__save-icon" />
							Save
						</button>
					</form>
				) }

				<div className="account-details__heading">Email</div>
				<div className="account-details__text">{ state.user.email }</div>
			</div>
		</>
	)
}