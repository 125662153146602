import React, { useState } from 'react'
import PropTypes from "prop-types"
import moment from 'moment'
import { Link } from 'gatsby';

import { formatPrice } from '../../helpers/currency';

import { RiExternalLinkLine, RiArrowDownSLine } from 'react-icons/ri'

export default function OrderItem( props ) {

	const order = props.order;
	const [ active, setActive ] = useState(false);

	const createProductDetails = () => {
		let products = [];

		order.products.forEach( product => {
			const foundQuantity = order.productQuantity.find( correctProduct => parseInt( correctProduct.id ) === parseInt( product.id ) );

			const updatedProduct = {
				...product,
				quantityBought: foundQuantity.quantity
			};

			products.push( updatedProduct );
		} )

		return products;
	}

	const products = createProductDetails();

	return (
		<div className="orders__item" key={ order.id }>

			<div className="orders__header">
				<div className="orders__number">Order #{ order.id }</div>
				<div className="orders__status">Status: { order.status }</div>
			</div>

			<div className="orders__body">
				<div className="orders__meta">
					Date Purchased: 
					<div className="orders__meta-info">{ moment( order.created_at ).format( 'DD/MM/YYYY' ) }</div>
				</div>
				<div className="orders__meta">
					Total:
					<div className="orders__meta-info">{ formatPrice( order.totalPrice ) }</div>
				</div>

				<button className="button button--no-margin button--icon" onClick={ () => setActive( !active ) }>
					View order
					<RiArrowDownSLine className={`orders__view-order-icon button__icon ${ active ? 'active' : '' }`} />
				</button>
			</div>

			<div className={ `orders__main ${ active ? 'active' : '' }`}>
				
				<div className="orders__wrapper">
					<div className="orders__inner-container">
						<div className="orders__inner-container-item orders__inner-container-item--shipping">
							<div className="orders__sub-heading">
								Delivery Address:
							</div>
							<div className="orders__address">
								<span className="orders__address-line">{ order.shippingName }</span>
								<span className="orders__address-line">{ order.shippingAddressLine1 }</span>
								<span className="orders__address-line">{ order.shippingAddressLine2 }</span>
								<span className="orders__address-line">{ order.shippingCity }</span>
								<span className="orders__address-line">{ order.shippingRegion }</span>
								<span className="orders__address-line">{ order.shippingPostcode }</span>
								<span className="orders__address-line">{ order.shippingCountry }</span>
							</div>
						</div>

						<div className="orders__inner-container-item orders__inner-container-item--shipping">
							<div className="orders__sub-heading">
								Billing Address:
							</div>
							<div className="orders__address">
								<span className="orders__address-line">{ order.billingName }</span>
								<span className="orders__address-line">{ order.billingAddressLine1 }</span>
								<span className="orders__address-line">{ order.billingAddressLine2 }</span>
								<span className="orders__address-line">{ order.billingCity }</span>
								<span className="orders__address-line">{ order.billingRegion }</span>
								<span className="orders__address-line">{ order.billingPostcode }</span>
								<span className="orders__address-line">{ order.billingCountry }</span>
							</div>
						</div>

						{/* <div className="orders__payment">
							<div className="orders__sub-heading">
								Payment Method:
							</div>
							<div className="orders__payment-method">
								Paypal
							</div>
						</div> */}
						<div className="orders__inner-container-item orders__inner-container-item--tracking">

							<div className="orders__sub-heading">
								Shipping Method:
							</div>
							<div className="orders__info">
								{ order.postageName  }
							</div>

							<div className="orders__sub-heading">
								Shipping Price:
							</div>
							<div className="orders__info">
								{ formatPrice( order.postagePrice ) }
							</div>

							<div className="orders__tracking-wrapper">
								{ order.status === "Shipped" && order.trackingNumber &&
									<>
										<div className="orders__sub-heading">
											Tracking Number:
										</div>
										<div className="orders__tracking-number">
											<a
												href={`https://www.royalmail.com/business/track-your-item#/tracking-results/${order.trackingNumber}`}
												target="_blank"
												rel="noreferrer"
												className="orders__tracking-link" >
													{ order.trackingNumber }
													<RiExternalLinkLine className="orders__tracking-link-icon" />
											</a>
										</div>
									</>
								}
							</div>
						</div>
					</div>

					<div className="orders__products-container">
						<div className="orders__sub-heading">
							Products:
						</div>
						<div className="orders__products-list">
							{ products.map( product => (
								<Link to={ `/products/${ product.slug }` } className="orders__product" key={ product.id }>
									<img
										src={ product.images[0].formats.thumbnail.url }
										alt={ product.images[0].alternativeText }
										className="orders__product-thumbnail"
										/>
									<div className="orders__product-info">
										<div className="orders__product-price">
											{ formatPrice(product.price) }
										</div>
										<div className="orders__product-name">
											{ product.name }
										</div>
										<div className="orders__product-quantity">
											Qty: { product.quantityBought }
										</div>
									</div>
								</Link>
								
							) ) }
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}

OrderItem.propTypes = {
	order: PropTypes.object
}
