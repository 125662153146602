import React from 'react'
import PropTypes from "prop-types"

import OrderItem from './OrderItem'

export default function OrdersList( props ) {

	const orders = props.orders;

	return (
		<div className="orders">
			<div className="orders__list">
				{ orders.map( order => (
					<OrderItem order={ order } key={ order.id } />
				) ) }
			</div>
		</div>
	)
}

OrdersList.propTypes = {
	orders: PropTypes.arrayOf(PropTypes.object)
}
