import React, { useEffect, useState } from 'react'
import axios from 'axios'

import useAuth from '../../hooks/useAuth'

import PageHeader from '../Header/PageHeader'
import OrdersList from '../Orders/OrdersList'

import { FaSpinner } from 'react-icons/fa'

export default function Orders() {

	const [ orders, setOrders ] = useState([])
	const [ loading, setLoading ] = useState(true)
	const { state } = useAuth()

	useEffect(() => {

		const fetchOrders = async () => {

			const { data: fetchedOrders } = await axios.get( `/api/orders/${ state.user.id }`)

			// reverse order so the latest order is at the top / first in the array
			fetchedOrders.reverse();

			setOrders( fetchedOrders );
			setLoading( false )
		}

		fetchOrders();
	}, [ state.user.id ]);
	
	return (
		<>
			<PageHeader title="My Orders"/>

			{ loading ? (
				<div className="orders__loading-container">
					<FaSpinner className="loader" />
					<h3 className="orders__loading-text">Fetching Orders</h3>
				</div>
			) : (
				<>
					{ orders.length ? (
						<OrdersList orders={ orders } />
					) : (
						<>
							<div className="orders__no-orders">Currently you have no orders. Once a purchase is made, view your orders here.</div>
						</>
					) }
				</>
			) }
		</>
	)
}

