import React from 'react';
import PropTypes from 'prop-types';

export default function PageHeader( { title, description, className } ) {
	return (
		<div className={ `page-header${ className ? ` ${ className }` : '' }` }>
			<h1 className="page-header__title">{ title }</h1>
			{ description &&
				<span className="page-header__sub">{ description }</span>
			}
		</div>
	);
}

PageHeader.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	className: PropTypes.string
};
