import React from 'react'

import { Link } from 'gatsby'

import useAuth from '../../hooks/useAuth'

import {
	RiUserLine,
	RiLockPasswordLine,
	RiWalletLine,
	RiLogoutBoxLine } from 'react-icons/ri'

export default function SideNavigation() {

	const { state, logout } = useAuth()

	const handleLogout = ( event ) => {
		event.preventDefault()
		logout()
	};

	return (
		<div className="account-navigation">
			<div className="account-navigation__container">
				<div className="account-navigation__greeting">Hi { state.user.firstName } { state.user.lastName }</div>
				
				<div className="account-navigation__links-container">
					<Link to="/account" className="account-navigation__link" activeClassName="active">
						<RiUserLine className="account-navigation__link-icon" />
						<span className="account-navigation__link-text">
							Account overview
						</span>
					</Link>
					<Link to="/account/orders" className="account-navigation__link" activeClassName="active">
						<RiWalletLine className="account-navigation__link-icon" />
						<span className="account-navigation__link-text">
							My orders
						</span>
					</Link>
					<Link to="/account/change-password" className="account-navigation__link" activeClassName="active">
						<RiLockPasswordLine className="account-navigation__link-icon" />
						<span className="account-navigation__link-text">
							Change password
						</span>
					</Link>
					<a href="#logout" className="account-navigation__link" onClick={ e => handleLogout(e) }>
						<RiLogoutBoxLine className="account-navigation__link-icon" />
						<span className="account-navigation__link-text">
							Logout
						</span>
					</a>
				</div>
			</div>
		</div>
	)
}