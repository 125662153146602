import React, { useState } from 'react'

import useAuth from '../../hooks/useAuth'

export default function ResetPassword() {

	const { state, updateProfile, login } = useAuth()

	const [ oldPassword, setOldPassword ] = useState("");
	const [ password, setPassword ] = useState("");
	const [ confirmPassword, setConfirmPassword ] = useState("");
	const [ passwordMatchError, setPasswordMatchError ] = useState(false);
	const [ oldPasswordError, setOldPasswordError ] = useState(false);
	const [ identifier ] = useState( state.user.email );

	const [ formSubmitted, setFormSubmitted ] = useState(false);

	const handleSubmit = async ( event ) => {
		event.preventDefault()

		// checks it logs in 
		try {
			await login( { identifier, password: oldPassword } ) 
		} catch( e ) {
			console.log("Error occurred during authentication")
			setOldPasswordError( true )
			return
		}
		if ( password !== confirmPassword ) {
			setPasswordMatchError( true )
			return;
		} else {
			setPasswordMatchError( false )
		}

		try {
			await updateProfile( { password } );
			setFormSubmitted( true )
		} catch ( error ) {
			console.log( error )
		}
	}

	return (
		<>
		{ !formSubmitted ? (
			<form  className="login__form form" onSubmit={ handleSubmit }>
					
				<div className="form__section-heading">
					Reset Your Password
				</div>

				<div className={`form__input-container ${ oldPasswordError ? 'error' : '' }`}>
					<label className="form__label" htmlFor="oldPassword">Old Password</label>
					<input className="form__input" type="password" name="oldPassword" id="oldPassword" value={ oldPassword } onChange={ e => { setOldPassword( e.target.value ) } } required/>
					<span className="form__input-error"> Your Password is incorrect</span>
				</div>

				<div className="form__input-container">
					<label className="form__label" htmlFor="password">New Password</label>
					<input className="form__input" type="password" name="password" id="password" value={ password } onChange={ e => { setPassword( e.target.value ) } } required/>
				</div>
				<div className={`form__input-container ${ passwordMatchError ? 'error' : '' }`}>
					<label className="form__label" htmlFor="confirmPassword">Confirm New Password</label>
					<input className="form__input" type="password" name="confirmPassword" id="confirmPassword" value={ confirmPassword } onChange={ e => { setConfirmPassword( e.target.value ) } } required/>
					<span className="form__input-error">Password confirmation does not match password!</span>
				</div>

				<button type="submit" className="form__submit">
					Change Password
				</button>
			</form>
		): (
			<p>Your password has been changed</p>
		) }
		</>
	)
}

